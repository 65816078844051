@import 'app.scss';
.wrapper {
  position: relative;
}

.swiper {
  overflow: visible;

  /* stylelint-disable-next-line selector-class-pattern, selector-pseudo-class-no-unknown */
  :global(.swiper-wrapper) {
    align-items: stretch;
  }

  /* stylelint-disable-next-line selector-class-pattern, selector-pseudo-class-no-unknown */
  :global(.swiper-slide) {
    height: unset;
  }
}

.navigation {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.button {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  margin: auto;

  &.previous {
    @include from-breakpoint(xl) {
      left: spacing(6);
    }
  }

  &.next {
    @include from-breakpoint(xl) {
      right: spacing(6);
    }
  }

  @include from-breakpoint(xl) {
    display: flex;
  }
}

.inStore {
  .button {
    display: flex;
    top: -128px;
    right: 0;
    bottom: unset;
    width: 90px;
    height: 90px;

    &:hover,
    &:focus {
      background-color: var(--color-white);

      span {
        color: var(--color-red-500);
      }
    }

    &.previous {
      right: calc(90px + 20px);
      left: unset;
    }
  }
}
